import { useClass } from '@breezerr';
import { ContextType } from '@brz-utils/create.pages/gatsby.page.context.types';
import { Backdrop, CircularProgress, Fade } from '@mui/material';
import { PageProps } from 'gatsby';
import React, { ReactNode, useContext } from 'react';


interface ContextModel<DataModel = any> {
    ready: boolean;
    setReady: (initialState: boolean | (() => boolean)) => void;
    data: DataModel;
    setData: React.Dispatch<React.SetStateAction<DataModel>>;
}

type ContextProps = {
    page: PageProps<any, ContextType>;
    children: ReactNode | ReactNode [];
};

const Context = React.createContext<ContextModel>({} as ContextModel);


export const GlobalContextProvider = (props: ContextProps) => {
    const [ready, setReady] = React.useState(props.page.pageContext.client ? false : true);
    const [start, setStart] = React.useState(props.page.pageContext.client ? false : true);

    /**
     * ToDo: Change this to be be a "key" based storage, so there are no conflicts of multiple components use the data "store"
     */
    const [data, setData] = React.useState(null);

    const value: ContextModel = {
        ready,
        setReady,
        data,
        setData
    };    

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            setTimeout(() => {
                React.startTransition(() => setStart(true))
            }, 300);
        }
    }, []);

    return (
        <Context.Provider value={value}>
            {/* Loading Overlay for Client-Only routes */}
            {props.page.pageContext.client ? (
            <Backdrop 
                open={!ready}
                
                unmountOnExit
                transitionDuration={{
                    appear: 0,
                    exit: 300
                }}
                slotProps={{
                    root: {
                        className: useClass('bg-color-white z-index-200 p-fixed w-100vw h-100vh top-0x left-0x')
                    }
                }}
            >
                <CircularProgress className={useClass('p-relative top-0rem')} color="primary" size="2rem" />
            </Backdrop>
            ) : null}

            {start ? props.children : null}
        </Context.Provider>
    );
};

// Hook
export const useGlobal = <DataModel,>(dataKey?: string): ContextModel<DataModel | null> => {

    if ( dataKey ) {
        const context = useContext<ContextModel<DataModel>>(Context);

        return {
            ...context,
            data: typeof context.data === 'object' ? ((context.data as {[key: string]: any})?.[dataKey] || null) : context.data,
            setData: (value: any) => {
                context.setData({
                    ...context.data,
                    [dataKey]: value
                })
            }
        };
    }

    return useContext<ContextModel<DataModel | null>>(Context);
}