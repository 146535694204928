export const PreviewQuery = `
    query tinaQuery($nodeId: String) {
        node(id: $nodeId) {
            id
            ... on PageGeneric {
                component
            }
            ... on PageBlog {
                component
            }
            ... on Category {
                component
            }
            ... on Post {
                component
            }
            ... on Article {
                component
            }
            ... on Author {
                component
            }
            ... on PageForm {
                component
            }
        }
    }
`;
