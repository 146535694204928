import { ConfigType } from "./types";

const config = {
    prefix: 'brz',
    unit: 'rem',
    spacer: 1,
    gutter: 3,
    fontFamily: `"proxima-nova", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif`,
    breakpoints: {
        '': { media: 0, container: '85vw', base: 9 },
        xxs: { media: 320, container: '85vw', base: 9 },
        xs: { media: 375, container: '85vw', base: 9 },
        sm: { media: 425, container: '85vw', base: 9 },
        smd: { media: 636, container: '85vw', base: 9 },
        md: { media: 768, container: '90vw', base: 9 },
        lg: { media: 1024, container: '100rem', base: 9 },
        xl: { media: 1280, container: 1210, base: 9 },
        xxl: { media: 1440, container: 1210, base: 10 },
    },
    colors: {
        text: {
            main: '#354A5E'
        },
        heading: {
            main: '#233849'
        },
        primary: {
            main: 700,
            100: ['#E2ECF5', '#354A5E'],
            200: ['#E1E5EF', '#354A5E'],
            400: ['#969DB1', '#fff'],
            500: ['#6B768F', '#fff'],
            700: ['#354A5E', '#fff'],
            800: ['#314354', '#fff'],
            900: ['#233849', '#fff']
        },
        secondary: {
            main: 500,
            100: ['#F9FAFB', '#233849'],
            300: ['#EBF5FB', '#233849'],
            400: ['#E2ECF5', '#233849'],
            500: ['#E5F1F7', '#233849'],
            600: ['#D5E5F3', '#233849'],
            700: ['#C7DAEB', '#233849'],
            900: ['#8AB5DB', '#233849'],
            1000: ['#78ACD9', '#233849'],
            1100: ['#8399AB', '#fff'],
            1200: ['#54687A', '#fff'],
        },
        accent: {
            main: 500,
            50:  ['#FFF5E3', '#233849'],
            100: ['#FFEDCE', '#233849'],
            200: ['#FEE3B6', '#233849'],
            300: ['#FFDCA1', '#233849'],
            500: ['#FFD080', '#233849'],
            600: ['#F3AE37', '#233849'],
            1000: ['#B8A687', '#fff'],
            1050: ['#A48756', '#fff'],
            1100: ['#706C5E', '#fff'],
            1200: ['#5A5647', '#fff'],
        },
        grey: {
            main: 200,
            50:  ['#FAFBFC', '#233849'],
            100: ['#F5F8FB', '#233849'],
            200: ['#F4F6F8', '#233849'],
            300: ['#ecf1f5', '#233849'],
            400: ['#E1E4ED', '#233849'],
            500: ['#C3C8D5', '#233849'],
            600: ['#90949E', '#233849'],
        },
        white: {
            main: 500,
            500: ['#fff', '#233849']
        },
        error: {
            main: 500,
            500: ['#FF6767', '#fff']
        },
        success: {
            main: 200,
            200: ['#E6F6F1', '#233849'],
            500: ['#BEDED4', '#233849'],
            800: ['#75B19E', '#233849'],
            900: ['#709F90', '#233849'],
        },
        transparent: {
            main: 500,
            500: ['rgba(0,0,0,0)', 'rgba(0,0,0,0)']
        },
        shadow: {
            main: 900,
            500: ['rgba(20, 20, 20, 0.04)', '#fff'],
            600: ['rgba(20, 20, 20, 0.06)', '#fff'],
            700: ['rgba(20, 20, 20, 0.05)', '#fff'],
            800: ['rgba(20, 20, 20, 0.3)', '#fff'],
            900: ['rgba(20, 20, 20, 0.4)', '#fff'],
        }
    }
}

export default config;