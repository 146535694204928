import { Config } from "@breezerr";
import type { Components, MenuItemProps } from "@mui/material";

export const MuiMenuTheme: Components = {
    MuiMenu: {
        styleOverrides: {
            paper: {
                display: 'flex',
                flexDirection: 'column'
            },
            list: {
                paddingTop: '1rem',
                paddingBottom: '1rem',
            }
        }
    },
    MuiMenuItem: {
        defaultProps: {
            component: 'div',
        } as Partial<MenuItemProps<"li", {}>> | undefined,
        styleOverrides: {
            root: {
                display: 'flex',
                boxSizing: 'border-box',
                padding: '1.25rem 2rem',
                fontSize: '1.4rem',
                lineHeight: '2rem',
                '&.Mui-disabled': {
                    textColor: Config.colors.primary['400'][0]
                }
            },
            dense: {
                padding: '0.75rem 1.5rem',
            },
            divider: {
                borderBottom: `1px solid ${Config.colors.grey['400'][0]}`
            },
        }
    }
};