import React from 'react';
import { useLocation } from '@reach/router'

// Lazy Components
const Pucompo = React.lazy(() => import(/* webpackMode: "lazy" */  '@brz-components/pucompo'));


const HashChangeContext = React.createContext<{ hash: string | null }>({ hash: null })

export const HashChangeWrapper = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
    const { hash } = useLocation();
    const [show, setShow] = React.useState<'pucompo' | null>(null);
    
    
    React.useEffect(() => {        

        if ( hash.match(/^#get-started-(.+)/g) ) {
            setShow('pucompo');
            return;
        }

        setShow(null);
    }, [hash]);


    return (
        <HashChangeContext.Provider 
            value={{
                hash
            }}
        >
            {children}

            {/* Pucompo */}
            {show === 'pucompo' ? (
                <React.Suspense>
                    <Pucompo />
                </React.Suspense>
            ) : null}
        </HashChangeContext.Provider>
    );
};

export const useHash = () => React.useContext(HashChangeContext);