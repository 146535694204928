import React from 'react';
import './src/utils/font.loader';
import version from './version.json';

import { MuiWrapper } from './src/mui/wrapper';
import { BreezerrWrapper } from './breezerr';
import { GlobalContextProvider } from './src/utils/contexts/global.context';
import { ReferralParamsWrapper } from './src/utils/contexts/referral.helper';
import { BreakpointWrapper } from './src/utils/contexts/is.breakpoint';
import { HashChangeWrapper } from './src/utils/hash.change';
import { GTM, GTMScript, LuckyOrangeScript } from './src/utils/seo/gtm';
import { WrapRootElementBrowserArgs, WrapPageElementBrowserArgs } from 'gatsby';


export const wrapRootElement = ({ element, pathname }: WrapRootElementBrowserArgs) => {
    return <>
        <ReferralParamsWrapper>
            <BreakpointWrapper>
                {element}
            </BreakpointWrapper>
        </ReferralParamsWrapper>
    </>;
};

export const wrapPageElement = ({ element, props }: WrapPageElementBrowserArgs) => {
    return <>
        <MuiWrapper>
            <BreezerrWrapper>
                <GlobalContextProvider page={props}>
                    <HashChangeWrapper>
                        {element}
                    </HashChangeWrapper>
                </GlobalContextProvider>
            </BreezerrWrapper>
        </MuiWrapper>

        <GTMScript />
        <LuckyOrangeScript />
        <GTM />
    </>
};

export const onClientEntry = () => {
    console.log('build:', version.build);
    
};

export const shouldUpdateScroll = () => {
    window.scrollTo({
        top: 0,
        behavior: 'instant'
    });
    
    return false;
};
