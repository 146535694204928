import React, { ReactNode } from 'react';
import client from '../../../tina/__generated__/client';
import { useTina, useEditState } from "tinacms/dist/react";
import { PreviewQuery } from './preview.query';
import { ADMIN_DIRNAME } from '@brz-env';

type TinaEditorProps<T> = {
    query: string;
    variables: object;
    data: T;
}

/**
 * Wrapper hook for useTina to add a refresh token as variable to force gatsby refersh of pages open without the editor.
 * @param props Same props passed to useTina hook
 * @returns data passed through useTina
 */
export function useTinaEditor<T extends object>(props: TinaEditorProps<T>) {
    const { edit } = useEditState();    

    return useTina({
        data: props.data,
        query: props.query,
        variables: {
            ...props.variables,
            // Helps with refreshing pages that are not in edit mode 
            // when the content file has been changed
            // refresh: !isEditing ? JSON.stringify(props.data) : null
        }
    })
};


export const isEditing = () => {
    if (typeof window === 'undefined' ) {
        return false;
    }

    if ( window.parent?.window?.location.pathname.indexOf(`/${ADMIN_DIRNAME}`) > -1 ) {
        return true;
    }

    return false;
};


type PreviewContextType = {
    setPreviewData: React.Dispatch<any>;
    previewData: any;
};
export const PreviewContext = React.createContext<PreviewContextType>({} as PreviewContextType);


export const Preview = ({ path, children }: { path: string, children: ReactNode | ReactNode[] }) => {
    const [previewData, setPreviewData] = React.useState<any>();
    const [queryData, setQueryData] = React.useState<any>();
    const { edit } = useEditState();
    

    React.useEffect(() => {
        if ( !edit ) return;

        // console.log('Loading template query data.');
        const nodeId = path.replace(/^\//, '').replace(/\//g, '\\');

        (async () => {
            const response = await client.request({
                query: PreviewQuery,
                variables: {
                    nodeId
                }
            });

            const component = response.data.node.component;

            if ( !component ) {
                throw new Error(`Error in Preview: Make sure to add "... on Collection { component }" to preview.query.ts`);
            }

            const url = `/tina/queries/templates/${component}/data.json`;
            const fetchRes = await fetch(url);
            

            if (fetchRes.ok) {
                const res = await fetchRes.json();
                setQueryData({
                    query: res.query,
                    variables: {
                        nodeId
                    }
                });
                // console.log('Template query data loaded.', res);
            } else {
                throw new Error('PreviewError: Could not fetch JSON for tina query');
            }

        })();
    }, [path, edit]);

    return (
        <PreviewContext.Provider value={{
            previewData,
            setPreviewData
        }}>
            {queryData ? (
                <>
                    <TinaPreviewEditor query={queryData} />
                    {children}
                </>
            ) : null}
        </PreviewContext.Provider>
    );
};


const TinaPreviewEditor = ({ query }: { query: any }) => {
    const { setPreviewData } = React.useContext(PreviewContext);

    const { data } = useTinaEditor<any>({
        data: {},
        query: query?.query || '',
        variables: query?.variables || {}
    });
    
    
    React.useEffect(() => {
        // Redirect to the page url if it's published
        if ( data?.node?.settings?.seo?.url && data?.node?.settings?.publish?.published && typeof window !== 'undefined' ) {
            console.info('Redirected to the actual page.');
            window.history.replaceState({}, '', data?.node?.settings?.seo?.url);
            return;
        }

        if ( data?.node ) {
            console.log('Setting preview data:', data);
            setPreviewData(data);
        }
    }, [data]);

    return null;
};