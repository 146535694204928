/**
 * Should only contain client-safe values
 */
export const branch = 'master';

export const env = {
    NAME: 'production',
    CLOUDINARY_FUNCTION_HOST: 'https://brz-web-tina-funcs-qa.azurewebsites.net',
    DEPLOYED_HOST: '', // Leave blank to use paths relative to the production domain
    CIO_WEB_LEAD_ID: '103b819728b7e1671859',
    BRZ_API_ENDPOINT: 'https://cms-funcs.meetbreeze.com/api',
    GTM_ID: 'GTM-PP2J436'
}
export const ADMIN_DIRNAME = 'breeze-admin';


/**
 * Shorthand for process.env.NODE_ENV === 'development'
 */
export const isDevelop = false;