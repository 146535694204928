import { createTheme } from "@mui/material/styles";
import { Config } from "@breezerr";


// Components
import { MuiButtonTheme } from "./components/mui.button";
import { MuiPaperTheme } from "./components/mui.paper";
import { MuiMenuTheme } from "./components/mui.menu";
import { MuiBackdropTheme } from "./components/mui.backdrop";
import { MuiTooltipTheme } from "./components/mui.tooltip";
import { MuiInputTheme } from "./components/mui.input";
import { MuiRadioTheme } from "./components/mui.radio";
import { MuiSliderTheme } from "./components/mui.slider";
import { MuiFabTheme } from "./components/mui.fab";



export const MuiTheme = createTheme({
    palette: {
        primary: convertPalette(Config.colors.primary),
        secondary: convertPalette(Config.colors.accent),
        error: convertPalette(Config.colors.error),
        grey: convertPalette(Config.colors.grey),
        success: convertPalette(Config.colors.success),

        background: {
            default: '#fff',
            paper: '#fff'
        }
    },
    typography: {
        fontFamily: Config.fontFamily,
        htmlFontSize: 15,
        fontSize: 14,
        button: {
            
        },
        h1: { fontFamily: Config.fontFamily  },
        h2: { fontFamily: Config.fontFamily  },
        h3: { fontFamily: Config.fontFamily  },
        h4: { fontFamily: Config.fontFamily  },
        h5: { fontFamily: Config.fontFamily  },
        h6: { fontFamily: Config.fontFamily  },
    },
    spacing: Config.spacer,

    components: {
        ...MuiButtonTheme,
        ...MuiPaperTheme,
        ...MuiMenuTheme,
        ...MuiBackdropTheme,
        ...MuiTooltipTheme,
        ...MuiInputTheme,
        ...MuiRadioTheme,
        ...MuiSliderTheme,
        ...MuiFabTheme,
        

    }
});



function convertPalette(palette: any) {
    // console.log(palette);
    
    return Object.assign({}, ...Object.keys(palette).map((key) => {
        const output: any = {};
        if( Array.isArray(palette[key]) ) {
            output[key] = palette[key][0];
        } else if(typeof palette[key] === 'number') {
            output[key.toString()] = palette[palette[key] as number][0];
            output['contrastText'] = palette[palette[key] as number][1];
        }
        return output;
    }));
};