import { BreakpointKeys } from "./breakpoints";
import config from "./config";
import { ColorHueType, ColorNames } from "./types";

type CreatePatternOptionsType = {
    breakpoints: boolean;
};


export const unitPattern = `x|px|rem|em|ch|v[wh]|%|deg`;
export const numValuePattern = `[0-9]+(?:\\.{0,1}[0-9]{1,2}){0,1}`;

export const calculateUnitValue = (value: number | null, unit: string) => {
    if( unit === 'x' ) {
        return `${value !== null ? config.spacer * value : 0}${config.unit}`;
    } else {
        return `${value !== null ? value : ''}${unit || ''}`;
    }
}; 

export const escapeSelector = (selector: string, isClass: boolean = true): string => {
    return `${isClass ? '.' : ''}${selector.replace(
        /(\:|\.|\%|\(|\))+/g, '\\$1'
    ).replace(
        /(\+)+/g, '\\$1'
    ).replace(/(\!)+/g, '\\$1')}`;
};

export const createPattern = (pattern: string, options?: CreatePatternOptionsType) => {
    return `^${options?.breakpoints ? '(?:([a-z]{0,3}):){0,1}' : ''}${pattern}$`;
}


export const breakpointKeys = Object.keys(config.breakpoints) as BreakpointKeys[];


function isBreakpointKey(value: any): value is BreakpointKeys {
    return breakpointKeys.includes(value);
}

/**
 * 
 * @param width 
 * 1. either a pixel number value to find the breakpoint container with
 * 2. or a breakpoint value to find the container with
 * @returns Breakpoint container value
 */
export const getContainerWidth = (width: number | BreakpointKeys | null) => {
    let output: string | number = 0;

    if ( !width ) {
        return output;
    }

    Object.values(config.breakpoints).forEach(breakpoint => {

        if (!isBreakpointKey(width) && width >= breakpoint.media) {
            output = breakpoint.container;
        }
    });

    if (isBreakpointKey(width) && config.breakpoints[width]) {
        output = config.breakpoints[width].container;
    }

    // console.log(output);
    return output;
};


export const color = (value: string, on = false) => {
    const matches = new RegExp(/([a-z]+)(?:\:{0,1})([0-9]+(?:\\.{0,1}[0-9]{1,2}){0,1})/).exec(value);

    if ( matches ) {
        const color = matches[1] as ColorNames;
        const rawHue = matches[2];

        const hue = !isNaN(parseInt(rawHue)) ? parseInt(rawHue) : null;
        
        return getColor(color, hue, undefined, on);
    }

    return '';
};


export function getColor(color: ColorNames, hue: number | null, operator?: '+' | '-', on: boolean = false): string {
    const colorObject: any = config.colors[color];

    if( !colorObject ) {
        console.trace(`Can't find color palette`, color);
        return '';
    }

    const mainHueObject = colorObject[colorObject.main];

    // console.log(colorObject);

    if( hue === null ) {
        // Return main color
        return Array.isArray(mainHueObject) ? mainHueObject[on ? 1 : 0] : mainHueObject;
    } else {
        // Find the hue
        const hueKeys = Object.keys(colorObject);
        const hueIndex = hueKeys.findIndex(value => value === colorObject.main.toString());
        const computedIndex = (operator === '+' ? hueIndex - hue : hueIndex + hue);
        const relativeHue = hueKeys[computedIndex];

        const hueObject: [string, string] = !operator ? colorObject[hue] : colorObject[relativeHue];

        // console.log(hueObject, hueKeys, colorObject.main, hueIndex, relativeHue, operator);
        if( Array.isArray(hueObject) ) {
            return hueObject[on ? 1 : 0];
        } else if( !hueObject || relativeHue === 'main' ) {
            if( operator ) {
                console.warn('Breezerr:', `Could not find a relative hue "${operator}${hue}" of "${color}" color.`);
            } else {
                console.warn('Breezerr:', `The hue "${hue}" of "${color}" is not defined.`);
            }
            
            return '';
        } else {
            return '';
        }
    }
}